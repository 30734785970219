// Generated by Framer (33d5032)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["FT6bS56dP"];

const serializationHash = "framer-9pf0s"

const variantClassNames = {FT6bS56dP: "framer-v-o1hig6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, imageRadius, width, ...props}) => { return {...props, tIR7jkG51: image ?? props.tIR7jkG51 ?? {src: "https://framerusercontent.com/images/WTxZwcNinFyvtSQa7ZuKUdGsOCA.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/WTxZwcNinFyvtSQa7ZuKUdGsOCA.png?scale-down-to=512 512w,https://framerusercontent.com/images/WTxZwcNinFyvtSQa7ZuKUdGsOCA.png 800w"}, vA_lfDfcR: imageRadius ?? props.vA_lfDfcR ?? 10} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};imageRadius?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tIR7jkG51, vA_lfDfcR, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FT6bS56dP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-o1hig6", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"FT6bS56dP"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(tIR7jkG51)}} className={"framer-1kjl88h"} layoutDependency={layoutDependency} layoutId={"N0iVYa7tz"} style={{borderBottomLeftRadius: vA_lfDfcR, borderBottomRightRadius: vA_lfDfcR, borderTopLeftRadius: vA_lfDfcR, borderTopRightRadius: vA_lfDfcR}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9pf0s.framer-avmgta, .framer-9pf0s .framer-avmgta { display: block; }", ".framer-9pf0s.framer-o1hig6 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 340px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 400px; }", ".framer-9pf0s .framer-1kjl88h { display: grid; flex: 1 0 0px; gap: 10px; grid-auto-rows: minmax(0, 1fr); grid-template-columns: repeat(2, minmax(200px, 1fr)); grid-template-rows: repeat(2, minmax(0, 1fr)); height: 1px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9pf0s.framer-o1hig6 { gap: 0px; } .framer-9pf0s.framer-o1hig6 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-9pf0s.framer-o1hig6 > :first-child { margin-top: 0px; } .framer-9pf0s.framer-o1hig6 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 340
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"tIR7jkG51":"image","vA_lfDfcR":"imageRadius"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerixGyMJW6Y: React.ComponentType<Props> = withCSS(Component, css, "framer-9pf0s") as typeof Component;
export default FramerixGyMJW6Y;

FramerixGyMJW6Y.displayName = "Image";

FramerixGyMJW6Y.defaultProps = {height: 340, width: 400};

addPropertyControls(FramerixGyMJW6Y, {tIR7jkG51: {__defaultAssetReference: "data:framer/asset-reference,WTxZwcNinFyvtSQa7ZuKUdGsOCA.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, vA_lfDfcR: {defaultValue: 10, title: "Image Radius", type: ControlType.Number}} as any)

addFonts(FramerixGyMJW6Y, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})